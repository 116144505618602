import * as React from 'react'
import { graphql } from 'gatsby'

import { useRecoilValue } from 'recoil'
import { marketCodeManager } from '@/recoil/marketCode'
import { isMobileState } from '@/recoil/layout'

import Layout from '@/layouts/Default'
import { Helmet } from 'react-helmet'
import Hero from '@/components/Hero'
import Button from '@/components/Button'

const NotFoundPage = (props) => {
  const { data, pageContext: { pagePath } } = props
  console.log(props)
  console.log(pagePath)
  console.log(data)
  const isMobile = useRecoilValue(isMobileState)
  const marketCode = useRecoilValue(marketCodeManager)
  const heroData = data.drupal.HeroQuery

  return (
    <Layout path={ pagePath } hrefLangs={ false } is404>
      <Helmet>
        <title>Cloudy Bay - Page Not Found</title>
        {/* <meta name='description' content='To receive more details about our Cloudy Bay wines or experiences in Marlborough or Central Otago, please get in touch with us.' /> */}
      </Helmet>
      <Hero
        subtitle='The requested page does not exist'
        title='Error 404'
        image={
          isMobile
            ? heroData?.fieldContactBackgroundMobile.url
            : heroData?.fieldContactBackgroundDesktop.url
        }
        circularButton={ [
          <Button to={ `/${marketCode}` }>Back to home page</Button>
        ] }
        shortPage
      />
    </Layout>
  )
}

export const query = graphql`
  query PageNotFoundDataQuery {
    drupal {
      HeroQuery: nodeById(id: "12") {
        ... on Drupal_NodeContactPage {
          fieldContactBackgroundMobile {
            url
            title
            alt
          }
          fieldContactBackgroundDesktop {
            url
            title
            alt
          }
        }
      }
    }
  }
`

export default NotFoundPage
