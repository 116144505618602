import PropTypes from 'prop-types'
import * as React from 'react'

import { useTheme } from '@emotion/react'
import { useRecoilValue } from 'recoil'

import Link from '@/components/LocalizedGatsbyLink'
import { isMobileState } from '@/recoil/layout'
import isExternalLink from '@/utils/isExternalLink'
import isPdf from '@/utils/isPdf'

import Icon from '@/components/Icon'

import { Button, Label, LabelContainer, Path, Wrapper } from './style'

const formatLabel = (label, nb = 4) => {
  let spaceLength = Math.floor((70 - label.length * nb) / nb)
  if (spaceLength >= 10) spaceLength = 6
  if (label.length > 9) spaceLength = 3

  return `${label}${String.fromCharCode(160).repeat(spaceLength)}`.repeat(nb)
}

const CircularLabelButton = ({
  id,
  secondId,
  linkTitle,
  color = 'green',
  className,
  to = '',
  onClick,
  target = '_self',
  icon = 'arrow-right',
  label = '',
  nbLabels = 4
}) => {
  const theme = useTheme()
  const isMobile = useRecoilValue(isMobileState)

  return (
    <Button
      className={ className }
      { ...((to && (isExternalLink(to) || isPdf(to))) && { as: 'a', href: to, target: target, title: label }) }
      { ...((to && !isExternalLink(to) && !isPdf(to)) && { as: Link, to, target: target, title: label }) }
      { ...(!to.length && { onClick }) }
      title={linkTitle ? linkTitle : label}
    >
      <Wrapper>
        <LabelContainer viewBox='0 0 180 180'>
          <Path
            d='M0,90C0,40.3,40.3,0,90,0s90,40.3,90,90s-40.3,90-90,90S0,139.7,0,90'
            id={label === "Central Otago" ? secondId : id}
          >
            <animateTransform
              attributeName='transform'
              begin='0s'
              dur='30s'
              type='rotate'
              from='0 90 90'
              to='360 90 90'
              repeatCount='indefinite'
            />
          </Path>
          <Label
            dy={ 11 }
            color={ theme.colors[color] }
          >
            <textPath xlinkHref={`#${id}`} textLength='562'>
              {formatLabel(label, nbLabels)}
            </textPath>
          </Label>
        </LabelContainer>
        <Icon
          name={ icon }
          size={ 25 }
          color={ theme.colors[color] }
        />
      </Wrapper>
      <span>(open in a new tab)</span>
    </Button>
  )
}

CircularLabelButton.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  nbLabels: PropTypes.number
}

export default CircularLabelButton
