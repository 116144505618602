import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

export const Button = styled.button`
  border: none;
  text-decoration: none;
  color: inherit;
  background: none;
  display: flex;
  transform: scale(1.5);
  ${mediaMax.xs} {
    transform: scale(.9);
  }

  span {
    position: absolute;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
  }
`

export const Wrapper = styled.div`
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${mediaMax.xs} {
    width: 10rem;
    height: 10rem;
  }
`

export const LabelContainer = styled.svg`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

export const Path = styled.path`
  fill: none;
`

export const Label = styled.text`
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  fill: ${({ color }) => color};
  ${mediaMax.xs} {
    font-size: 1.5rem;
  }

  @-moz-document url-prefix() {
    font-size: 1.45rem;
  }
`
